<template>
  <div>
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="py-2 px-4 border-b">Market</th>
            <th class="py-2 px-4 border-b">Entry</th>
            <th class="py-2 px-4 border-b">Profit %</th>
            <th class="py-2 px-4 border-b">Quantity</th>
            <th class="py-2 px-4 border-b">Profit</th>
            <th class="py-2 px-4 border-b">Target</th>
            <th class="py-2 px-4 border-b">Stop Loss</th>
            <th class="py-2 px-4 border-b">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="position in positions" :key="position.tradeId">
            <td class="py-2 px-4 border-b">
              <a
                :href="getMarketLink(position.market)"
                target="_blank"
                rel="noopener noreferrer"
                class="text-blue-500 hover:underline"
              >
                {{ position.market }}
              </a>
            </td>
            <td class="py-2 px-4 border-b">{{ formatNumber(position.price, 3) }}</td>
            <td
              class="py-2 px-4 border-b"
              :class="position.profitPercentage >= 0 ? 'text-green-500' : 'text-red-500'"
            >
              {{ formatPercentage(position.profitPercentage) }}
            </td>
            <td class="py-2 px-4 border-b">{{ formatNumber(position.quantity, 3) }}</td>
            <td
              class="py-2 px-4 border-b"
              :class="position.profit >= 0 ? 'text-green-500' : 'text-red-500'"
            >
              {{ formatNumber(position.profit, 2) }}
            </td>
            <td class="py-2 px-4 border-b">{{ formatNumber(position.target, 3) }}</td>
            <td class="py-2 px-4 border-b">{{ formatNumber(position.stopLoss, 3) }}</td>
            <td class="py-2 px-4 border-b">{{ formatDate(position.date) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PositionTable',
  props: {
    positions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(timestamp) {
      if (!timestamp) return 'N/A'
      const date = new Date(timestamp)
      return date.toLocaleString()
    },
    formatNumber(value, precision) {
      if (value === null || value === undefined) return 'N/A'
      return parseFloat(value).toFixed(precision)
    },
    formatPercentage(value) {
      if (value === null || value === undefined) return 'N/A'
      return `${parseFloat(value).toFixed(2)}%`
    },
    getMarketLink(market) {
      if (!market || typeof market !== 'string') return '#'
      
      // Attempt to split the market string into base and quote
      // Common quote assets on Binance include USDT, BUSD, BTC, ETH, etc.
      // This example assumes USDT as the quote; you may need to adjust based on your data
      const quoteAssets = ['USDT', 'BUSD', 'BTC', 'ETH', 'BNB', 'EUR', 'GBP', 'TRY', 'RUB', 'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'HKD', 'INR', 'JPY', 'KRW', 'MXN', 'NZD', 'PLN', 'SGD', 'UAH', 'ZAR']
      
      let quote = 'USDT' // Default quote
      for (let qa of quoteAssets) {
        if (market.endsWith(qa)) {
          quote = qa
          break
        }
      }
      
      const base = market.slice(0, market.length - quote.length)
      
      // Handle cases where base might be empty or unexpected
      if (!base) return '#'
      
      return `https://www.binance.com/fr/trade/${base}_${quote}?type=spot`
    },
  },
})
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}
th {
  background-color: #f3f4f6;
}
@media (max-width: 768px) {
  /* For mobile devices */
  th,
  td {
    padding: 8px;
    font-size: 14px;
  }
  th {
    font-size: 14px;
  }
}
</style>
